export class WidgetPreview {
    _name;
    _widget;
    _element;
    constructor(name, widget) {
        this._name = name;
        this._widget = widget;
        this._element = document.createElement('div');
        this._element.innerHTML = `
            <div>
                    ${this._widget.getAsHTML()}
                <i>
                    &lt;${this._name}&gt;
                </i>
            </div>
        `;
        this._element.addEventListener('click', (e) => {
            document.getElementById('gridContainer').append(document.createElement(this._name));
        });
    }
    getAsHTML() {
        return this._element.innerHTML;
    }
    connectedCallback() {
    }
}
