import { WidgetPropertyData } from "./WidgetPropertyData";
export class SelectedObject {
    //region singleton
    static instance = new SelectedObject();
    static getInstance() {
        return this.instance;
    }
    constructor() {
    }
    //endregion
    _selectedWidget;
    get selectedWidget() {
        return this._selectedWidget;
    }
    set selectedWidget(value) {
        if (this._selectedWidget) {
            this._selectedWidget.style.boxShadow = "";
        }
        value.style.boxShadow = "0 0 20px 0 #FF0000";
        this._selectedWidget = value;
        WidgetPropertyData.getInstance().update(value);
    }
    unselect() {
        if (this._selectedWidget) {
            WidgetPropertyData.getInstance().flush();
            this._selectedWidget.style.boxShadow = "";
        }
    }
}
