import 'bootstrap/dist/js/bootstrap.js';
import './main.scss';
import { init } from "./Tools/Mapping";
import { Widget } from "./Widgets/Immutable/MovableObject";
import './Assets/Framework/Widget.scss';
import { SelectedObject } from "./Tools/Backend/SelectedObject";
let json = {
    /*"test-widget": {
        resizable:false
    },*/
    "testing-widget": {
        resizable: true,
        properties: {
            "bind": {
                type: "input",
                optional: false
            }
        }
    },
    "svg-path": {
        resizable: true,
        properties: {}
    }
};
export const widgetPreviews = [];
Object.entries(json).forEach((j) => {
    const wg = class extends Widget {
        constructor() {
            super(j[0], j[1].resizable, j[1].properties);
        }
    };
    customElements.define(j[0], wg);
    widgetPreviews.push(new wg());
});
init();
document.addEventListener('DOMContentLoaded', () => {
    document.getElementById("gridContainer").onclick = () => {
        SelectedObject.getInstance().unselect();
    };
});
window.addEventListener('beforeunload', () => {
    SelectedObject.getInstance().unselect();
    window.opener.postMessage({ type: "save", content: document.getElementById('gridContainer').innerHTML });
});
