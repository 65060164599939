import { Widget } from "../Widgets/Immutable/MovableObject";
export class DraggableLine extends Widget {
    container = null;
    line = null;
    startHandle = null;
    endHandle = null;
    isDragging = false;
    activeHandle = null;
    pivot = { x: 100, y: 100 };
    endpoint = { x: 200, y: 200 };
    offset = { x: 0, y: 0 };
    constructor() {
        super("dragable-line", true);
        const shadow = this.attachShadow({ mode: "open" });
        shadow.innerHTML = `
            <style>
                #line-container {
                    position: absolute;
                }

                #line {
                    position: relative;
                    height: 2px;
                    background-color: black;
                }

                .handle {
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background-color: red;
                    cursor: pointer;
                }

                #start-handle {
                    top: -4px;
                    left: -5px;
                }

                #end-handle {
                    top: -4px;
                    right: -5px;
                }
            </style>
            <slot></slot>
        `;
        this.container = shadow.getElementById("line-container");
        this.line = shadow.getElementById("line");
        this.startHandle = shadow.getElementById("start-handle");
        this.endHandle = shadow.getElementById("end-handle");
        this.addEventListeners();
        this.updateLine();
    }
    snapAngleTo45Degrees(angle) {
        const snap = 0.1;
        return Math.round(angle / snap) * snap;
    }
    updateLine() {
        const dx = this.endpoint.x - this.pivot.x;
        const dy = this.endpoint.y - this.pivot.y;
        const length = Math.sqrt(dx * dx + dy * dy);
        let angle = Math.atan2(dy, dx) * (180 / Math.PI);
        angle = this.snapAngleTo45Degrees(angle);
        this.line.style.width = `${length}px`;
        this.line.style.transform = `rotate(${angle}deg)`;
        this.container.style.left = `${this.pivot.x}px`;
        this.container.style.top = `${this.pivot.y}px`;
    }
    startDrag(handle, event) {
        this.isDragging = true;
        this.activeHandle = handle;
        this.offset.x = event.clientX;
        this.offset.y = event.clientY;
        event.preventDefault();
    }
    drags(event) {
        if (!this.isDragging || !this.activeHandle)
            return;
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        if (this.activeHandle === this.startHandle) {
            this.pivot.x += mouseX - this.offset.x;
            this.pivot.y += mouseY - this.offset.y;
        }
        else if (this.activeHandle === this.endHandle) {
            this.endpoint.x += mouseX - this.offset.x;
            this.endpoint.y += mouseY - this.offset.y;
        }
        this.offset.x = mouseX;
        this.offset.y = mouseY;
        this.updateLine();
    }
    endDrag() {
        this.isDragging = false;
        this.activeHandle = null;
    }
    addEventListeners() {
        this.endHandle?.addEventListener("mousedown", (event) => {
            this.startDrag(this.endHandle, event);
        });
        document.addEventListener("mousemove", (event) => this.drags(event));
        document.addEventListener("mouseup", () => this.endDrag());
    }
}
