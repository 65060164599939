import { SelectedObject } from "./SelectedObject";
export class WidgetPositionData {
    //region singleton
    static instance = new WidgetPositionData();
    static getInstance() {
        return this.instance;
    }
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this._xAxisData = document.getElementById("x-axis-data");
            this._yAxisData = document.getElementById("y-axis-data");
            this._zAxisData = document.getElementById("z-axis-data");
            this._widthData = document.getElementById("width-data");
            this._heightData = document.getElementById("height-data");
            this._xAxisData.onchange = () => {
                SelectedObject.getInstance().selectedWidget?.setX(Number(this._xAxisData.value || 0));
            };
            this._yAxisData.onchange = () => {
                SelectedObject.getInstance().selectedWidget?.setY(Number(this._yAxisData.value || 0));
            };
            this._zAxisData.onchange = () => {
                SelectedObject.getInstance().selectedWidget?.setZ(Number(this._zAxisData.value || 0));
            };
            this._widthData.onchange = () => {
                SelectedObject.getInstance().selectedWidget?.setSize(Number(this._widthData.value || 0), Number(this._heightData.value || 0));
            };
            this._heightData.onchange = () => {
                SelectedObject.getInstance().selectedWidget?.setSize(Number(this._widthData.value || 0), Number(this._heightData.value || 0));
            };
        });
    }
    //endregion
    _xAxisData;
    _yAxisData;
    _zAxisData;
    _widthData;
    _heightData;
    setPosition(x, y, z) {
        if (x) {
            this._xAxisData.value = String(x);
        }
        if (y) {
            this._yAxisData.value = String(y);
        }
        if (z) {
            this._zAxisData.value = String(z);
        }
    }
    setSize(width, height) {
        this._widthData.value = String(width);
        this._heightData.value = String(height);
    }
}
