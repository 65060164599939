import { GetUrlParameters } from "../Utils/GetUrlParameters";
export class WidgetGrid extends HTMLElement {
    width;
    height;
    constructor() {
        super();
        this.width = Number(GetUrlParameters("width") ?? 1920);
        this.height = Number(GetUrlParameters("height") ?? 1080);
    }
    connectedCallback() {
        this.innerHTML = `
            <div style="height: ${this.height}px;width: ${this.width}px;position: relative;" id="gridContainer">
            </div>   
            `;
    }
}
