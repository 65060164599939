import { widgetPreviews } from "../main";
import { WidgetPreview } from "./WidgetPreview";
export class WidgetSelection extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.appendChild(document.createElement('input'));
        widgetPreviews.forEach((wg) => {
            this.appendChild(new WidgetPreview(wg.getName(), wg)._element);
        });
    }
    refresh() {
        this.innerHTML = "";
        this.appendChild(document.createElement('input'));
        widgetPreviews.forEach((wg) => {
            this.appendChild(new WidgetPreview(wg.getName(), wg)._element);
        });
    }
}
