import { SelectedObject } from "../../Tools/Backend/SelectedObject";
import { WidgetPositionData } from "../../Tools/Backend/WidgetPositionData";
export class MovableObject extends HTMLElement {
    isResizing;
    active;
    currentX = 0;
    currentY = 0;
    initialX = 0;
    initialY = 0;
    xOffset = 0;
    yOffset = 0;
    _locked;
    constructor() {
        super();
        this.isResizing = false;
        this.active = false;
        this._locked = false;
    }
    extractXYFromTranslate3d(transform) {
        // Match the translate3d function call with regular expression
        const regex = /translate3d\(([^,]+)px, ([^,]+)px, [^)]+\)/;
        const matches = transform.match(regex);
        // If no match, return {0, 0}
        if (!matches) {
            return { x: 0, y: 0 };
        }
        // Parse the matched values
        const x = parseFloat(matches[1]);
        const y = parseFloat(matches[2]);
        return { x, y };
    }
    connectedCallback() {
        this.style.position = "absolute";
        this.style.overflow = "hidden";
        if (this.style.transform) {
            this._locked = true;
            let temp = this.extractXYFromTranslate3d(this.style.transform);
            this.yOffset = temp.y;
            this.xOffset = temp.x;
            this.style.cursor = "default";
            this.style.resize = "unset";
        }
        else {
            this.style.cursor = "move";
            this.style.resize = "both";
        }
        this.onmousedown = (e) => {
            if (this._locked) {
                return;
            }
            if (e.offsetX > this.clientWidth - 10 && e.offsetY > this.clientHeight - 10) {
                this.isResizing = true;
            }
            else {
                this.dragStart(e);
            }
        };
        this.onmouseup = (e) => {
            if (this.isResizing) {
                this.isResizing = false;
            }
            else {
                this.dragEnd(e);
            }
        };
        this.onmouseleave = (e) => {
            if (this.isResizing) {
                this.isResizing = false;
            }
            else {
                this.dragEnd(e);
            }
        };
    }
    lock() {
        this._locked = true;
        this.style.cursor = "normal";
    }
    unlock() {
        this._locked = false;
        this.style.cursor = "move";
    }
    dragStart(e) {
        this.initialX = e.clientX - this.xOffset;
        this.initialY = e.clientY - this.yOffset;
        if (e.target === this) {
            this.active = true;
        }
    }
    dragEnd(e) {
        this.initialX = this.currentX;
        this.initialY = this.currentY;
        this.active = false;
        //this.snapToGrid();
    }
    drag(e) {
        if (this.active) {
            e.preventDefault();
            this.currentX = e.clientX - this.initialX;
            this.currentY = e.clientY - this.initialY;
            this.setTranslate(this.currentX, this.currentY, this);
        }
    }
    setTranslate(xPos, yPos, el) {
        // Ensure `this` refers to a DOM element
        if (!(this instanceof HTMLElement)) {
            throw new Error("The 'this' context must be an HTMLElement.");
        }
        // Get the current transform value
        const currentTransform = el.style.transform || '';
        // Get the bounding box of the parent element
        const containerRect = this.parentElement.getBoundingClientRect();
        // Calculate the maximum allowed x and y positions
        const maxX = (containerRect.width - el.offsetWidth);
        const maxY = (containerRect.height - el.offsetHeight);
        // Clamp x and y positions to fit within the parent element, factoring in the scale
        xPos = Math.min(Math.max(0, xPos), maxX);
        yPos = Math.min(Math.max(0, yPos), maxY);
        // Set the new position
        this.xOffset = xPos;
        this.yOffset = yPos;
        // Build the new transform value
        const translate3d = `translate3d(${this.xOffset}px, ${this.yOffset}px, 0)`;
        this.style.transform = `${translate3d}`;
        WidgetPositionData.getInstance().setPosition(this.xOffset, this.yOffset, 0);
    }
}
export class SizableObject extends MovableObject {
    _width;
    _height;
    _origHeight = 0;
    _origWidth = 0;
    constructor() {
        super();
        this._width = 0;
        this._height = 0;
    }
    connectedCallback() {
        super.connectedCallback();
        this.onmousemove = (e) => {
            if (this._locked) {
                return;
            }
            if (this.isResizing) {
                this.resize(e);
            }
            else {
                this.drag(e);
            }
        };
    }
    resize(e) {
        // Get the bounding box dimensions
        const boundingRect = this.getBoundingClientRect();
        // Set the new width and height directly based on the bounding box
        this.style.width = `${boundingRect.width}px`;
        this.style.height = `${boundingRect.height}px`;
        // Get the current transform value
        const currentTransform = this.style.transform || '';
        // Extract the existing translate3d part of the transform
        const translate3dMatch = currentTransform.match(/translate3d\([^)]+\)/);
        const existingTranslate3d = translate3dMatch ? translate3dMatch[0] : '';
        // Apply the combined transformations without any scaling
        this.style.transform = `${existingTranslate3d}`;
        WidgetPositionData.getInstance().setSize(this.clientWidth, this.clientHeight);
    }
    setSize(width, height) {
        this.style.width = `${width}px`;
        this.style.height = `${height}px`;
    }
}
export class ContainerObject extends SizableObject {
    _window;
    _allowContainer;
    constructor(allowContainer) {
        super();
        this._allowContainer = allowContainer;
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this._allowContainer) {
            return;
        }
        this.oncontextmenu = (e) => {
            /* e.preventDefault();
             if(this._window){
                 this._window.close();
             }
             this._window = window.open("", "Ratting", "width=550,height=170,left=0,top=0,toolbar=0,status=0");
             */
        };
    }
}
export class Widget extends ContainerObject {
    _tagName;
    _preview;
    _properties;
    constructor(tagName, resizable, properties) {
        super(resizable);
        this._tagName = tagName;
        this._properties = properties;
        this._preview = `
            <div class="${tagName}-preview" >
            
            </div>
        `;
        this.onclick = (e) => {
            e.stopPropagation();
            SelectedObject.getInstance().selectedWidget = this;
            WidgetPositionData.getInstance().setPosition(this.xOffset, this.yOffset, 0);
            WidgetPositionData.getInstance().setSize(Number(this.clientWidth), Number(this.clientHeight));
        };
    }
    setX(x) {
        this.setTranslate(x, this.yOffset, this);
    }
    setY(y) {
        this.setTranslate(this.xOffset, y, this);
    }
    setZ(z) {
    }
    getAsHTML() {
        return this._preview;
    }
    getName() {
        return this._tagName;
    }
    getProperties() {
        return this._properties;
    }
}
