import { Widget } from "../Widgets/Immutable/MovableObject";
import { widgetPreviews } from "../main";
export class CreateTemplateButton extends HTMLElement {
    _window;
    constructor() {
        super();
    }
    connectedCallback() {
        this.onclick = () => {
            if (this._window) {
                this._window.close();
            }
            let width = Number(document.getElementById(this.getAttribute("template-width") ?? "")?.value ?? 890);
            let height = Number(document.getElementById(this.getAttribute("template-height") ?? "")?.value ?? 500);
            this._window = window.open(`createTemplate.html?width=${width}&height=${height}`, "Ratting", `width=1920,height=1080,left=0,top=0,toolbar=0,status=0`);
            window.addEventListener("message", (event) => {
                if (event.data.type === "save") {
                    console.log(event.data.content);
                    const wg = class extends Widget {
                        constructor() {
                            super("my-test", false);
                            this.attachShadow({ mode: "open" });
                            this.shadowRoot.innerHTML = `
                                <style>
                                    :host {
                                        border: 3px dashed red;
                                        height: ${height}px;
                                        width : ${width}px;
                                    }
                                </style>
                                <slot></slot>
                            `;
                        }
                        connectedCallback() {
                            this.innerHTML = `${event.data.content}`;
                            super.connectedCallback();
                        }
                    };
                    customElements.define("my-test", wg);
                    widgetPreviews.push(new wg());
                    let c = document.getElementById("widgetSelection");
                    c.refresh();
                }
            });
        };
    }
}
