import { WidgetSelection } from "./WidgetSelection";
import { MovableObject } from "../Widgets/Immutable/MovableObject";
import { CreateTemplateButton } from "./CreateTemplateButton";
import { WidgetGrid } from "./WidgetGrid";
import { DraggableLine } from "./DraggableLine";
import { AttributeInput } from "./Attributes/Attribute";
export const init = () => {
    //customElements.define('testing-object',Widget);
    customElements.define('widget-grid', WidgetGrid);
    customElements.define("movable-object", MovableObject);
    customElements.define('widget-selection', WidgetSelection);
    customElements.define('create-template', CreateTemplateButton);
    customElements.define("draggable-line", DraggableLine);
    customElements.define("attr-input", AttributeInput);
};
