export class WidgetPropertyData {
    //region singleton
    static instance = new WidgetPropertyData();
    static getInstance() {
        return this.instance;
    }
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this._objectProperty = document.getElementById("objectProperty");
        });
    }
    //endregion
    _objectProperty;
    update(widget) {
        this.flush();
        let properties = widget.getProperties();
        Object.entries(properties).forEach((key) => {
            this._objectProperty.innerHTML += `
                <div style="display: flex;width: 100%;">
                    <label>${key[0]}</label>
                    <input type="${key[1].type}" value="${widget.getAttribute(`${key[0]}`)}"/>
                </div>
            `;
        });
    }
    flush() {
        this._objectProperty.innerHTML = ``;
    }
}
